.providerButton {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.login  {
    max-width: 300px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}